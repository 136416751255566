import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import deposits from "../../app/components/transaction/Deposits";
import css from "../css/Addcase.module.css";
import { Link } from "react-router-dom";
//import Rightcontainer from "../Components/Rightcontainer";
import "../css/Loader.css";
import Swal from "sweetalert2";
// import findGif from "/";
import Header from "../Components/Header";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
const Addcase = ({ walletUpdate }) => {
  const history = useHistory();
  let method = useRef();
  let checkInterval;
  const [userAllData, setUserAllData] = useState();

  const [global, setGlobal] = useState(100);
  const [next, setNext] = useState(1);
  const [process, setProcess] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [totalDeposit, settotalDeposit] = useState(0);
  const [isverified, setisverified] = useState("");

  const [isCashFreeActive, setCashFreeActive] = useState(false);
  const [isPhonePeActive, setPhonePeActive] = useState(false);
  const [isRazorPayActive, setRazorPayActive] = useState(false);
  const [isDecentroActive, setDecentroActive] = useState(false);
  const [isUpiGatewayActive, setUpiGatewayActive] = useState(false);
  const [isUpiGatewayActive2, setUpiGatewayActive2] = useState(false);
  const [isUpiPayActive, setIsUpiPayActive] = useState(false);
  const [isUpiImbActive, setIsUpiImbActive] = useState(false);
  const [isUpiGActive, setIsUpiGActive] = useState(false);
  const [isGtelGatewayActive, setGtelGatewayActive] = useState(false);

  const [isManualPaymentActive, setManualPaymentActive] = useState(false);
  const [isManualUPIQR, setManualUPIQR] = useState(false);

  const [RazorPayKey, setRazorPayKey] = useState(false);
  const [RazorPayAccountName, setAccountName] = useState("Skill Based Gaming");

  const [qrCode, setQrCode] = useState();
  const [walletOption, setWalletOption] = useState("airtel");
  const [bankCode, setBankCode] = useState(3003);

  const [account_mail_id, setAccount_mail_id] = useState();
  const [account_name, setAccount_name] = useState();
  const [accountPhone, setAccountPhone] = useState();

  const [scrnshot, setScrnshot] = useState(null);
  const [scrnshot1, setScrnshot1] = useState("");
  const [upiUtrNum, setupiUtrNum] = useState("");
  const [isMypayActive, setIsMypayActive] = useState(false);

  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    setScrnshot(e.target.files[0]);
  };

  const ManualPayment = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const formData = new FormData();

    formData.append("Transaction_Screenshot", scrnshot);
    formData.append("amount", global);
    formData.append("referenceId", upiUtrNum);

    const response = await axios.post(
      baseUrl + `manual/deposit/txn`,
      formData,
      { headers }
    );
    //console.log(response.data);
    if (response.data.status === "Pending") {
      setTimeout(() => {
        axios
          .get(baseUrl + `manual/depositstatus/${response.data._id}`, {
            headers,
          })
          .then((res) => {
            //console.log(res);
            const icon = res.data.status === "PAID" ? "success" : "danger";
            var title = "";
            if (res.data && res.data.status === "PAID") {
              title = "Deposit submited successfully";
            } else if (res.data && res.data.status === "Pending") {
              title = "Deposit Transaction in proccess.";
            } else if (!res.data.status) {
              title = "Deposit Transaction Rejected Due to invalid UTR Number.";
            }

            history.push("/");
            setTimeout(() => {
              setProcess(false);
              Swal.fire({
                title: title,
                icon: icon,
                confirmButtonText: "OK",
              });
            }, 1000);
          });
      }, 30000);
      setProcess(true);
    } else {
      setProcess(false);
      Swal.fire({
        title: "Deposit Falied",
        icon: "danger",
        confirmButtonText: "OK",
      });
    }
  };

  //Function to load razorpay script for the display of razorpay payment SDK.
  function loadRazorpayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  //function will get called when clicked on the pay button.
  async function displayRazorpayPaymentSdk(
    channel,
    method,
    upiMethod,
    razorpay
  ) {
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
    }
    //setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // creating a new order and sending order ID to backend
    const response = await axios.post(
      baseUrl + `user/razorpay_order`,
      {
        amount: global,
        channel: channel,
        payment_method: method,
        provider: walletOption,
        bankCode: bankCode,
        account_name: account_name,
        payment_gatway: razorpay,
      },
      { headers }
    );

    if (!response) {
      alert("Server error. please check are you onlin?");
      return;
    }

    //console.log(response.data.orderdata);
    // Getting the order details back
    let order_id = response.data.txnID;
    let order_token = response.data.orderdata.id;
    const data = response.data.orderdata;
    const options = {
      key: RazorPayKey,
      name: RazorPayAccountName,
      description: "Skill Based Game Tournament",
      order_id: data.id,
      prefill: {
        name: account_name,
        email: account_mail_id,
        contact: accountPhone,
      },
      handler: async (response) => {
        //console.log(response)
        try {
          const paymentId = response.razorpay_payment_id;

          //const url = baseUrl+`rozarpay/capture/${paymentId}`;
          //const captureResponse = await axios.post(url, {},{headers})
          //console.log(captureResponse.data);
          checkrazorpaydeposit(order_id, order_token, "SUCCESS", paymentId);
        } catch (err) {
          checkrazorpaydeposit(order_id, order_token, "FAILED");
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const checkrazorpaydeposit = (
    order_id,
    order_token,
    order_status,
    paymentId
  ) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `razorpaydesposit/response`,
        { order_id, order_token, order_status, paymentId },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        history.push("/landing");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  //use for decentrodepositeupi
  const decentroDepositeUpi = (channel, method, upiMethod, decentropay) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get("https://morniinc.in/payment.php?amount=" + global, {
        accountID: "AID_2309051230571814",
        secret_key: "KEY_9e88747cf61aede3bffe95122ded7372",
        payment_id: "2345678uyf",
        payment_purpose: "game",
        buyer_name: "pradeep",
        buyer_phone: "9236547898",
        buyer_email: "p@gmail.com",
        pay_amount: global,
      })
      .then((res) => {
        console.log(res);
        if (res.data.response.status === "Success") {
          let order_id = "res.data.txnID";
          let order_token = "res.data.data.decentroTxnId";
          //setProcess(false);
          window.open(res.data.response.pay_link);
          setTimeout(() => {
            //checkdecentrodeposit(order_id, order_token)
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  };

  //use for decentrodepositeupi
  const phonePeDepositeUpi = (channel, method, upiMethod, phonepay) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        "https://api.rjludo.com/phonepev1/accept?amount=" +
          global +
          "&id=" +
          access_token,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          payment_gatway: phonepay,
        },
        { headers }
      )
      .then((res) => {
        console.log(JSON.stringify(res));
        // console.log(res.data.data.data.transactionId);
        // console.log(res.data.txnID);
        if (res.data.success === true) {
          let order_id = res.data.data.txnID;
          let order_token = res.data.data.txnID;
          //setProcess(false);
          window.location.assign(
            res.data.data.instrumentResponse.redirectInfo.url
          );
          //   var windowReference = window.open();

          //     myService.getUrl().then(function(url) {
          //          windowReference.location = url;
          //     });
          setTimeout(() => {
            checkphonepedeposit(order_id, order_token);
            //history.push('/');
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  };

  const checkphonepedeposit = (order_id, order_token) => {
    console.log(order_token);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `phonepestatus/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction in failed";

        if (res.data.status == "Pending") {
          setTimeout(() => {
            checkphonepedeposit(order_id, order_token);
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: "OK",
            });
          }, 6000);
        } else {
          history.push("/");
          setTimeout(() => {
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: "OK",
            });
          }, 1000);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  //use for upigatway
  const [showDetails, setShowDetails] = useState(true);

  const handleButtonClick = () => {
    setShowDetails((prevShowDetails) => !prevShowDetails);
  };

  const depositUpiGateway = (channel, method, upiMethod, upigateway) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/depositeupi`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          account_mail_id: account_mail_id,
          payment_gatway: upigateway,
        },
        { headers }
      )

      .then((res) => {
        if (res.data.data) {
          let order_id = res.data.txnID;
          let order_token = res.data.txnID;

          window.location.assign(res.data.data.data.payment_url);

          setTimeout(() => {
            checkupideposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.data.msg,
            icon: "succ",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
  };
  const depositUpiGateway2 = (channel, method, upiMethod, upigateway) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/depositeupi2`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          account_mail_id: account_mail_id,
          payment_gatway: upigateway,
        },
        { headers }
      )

      .then((res) => {
        if (res.data.data) {
          let order_id = res.data.txnID;
          let order_token = res.data.txnID;

          window.location.assign(res.data.data.data.payment_url);

          setTimeout(() => {
            checkupideposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: "Failed",
            icon: "succ",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
  };
  const depositGtelGateway = (channel, method, upiMethod, upigateway) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/gtelupi`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          account_mail_id: account_mail_id,
          payment_gatway: upigateway,
        },
        { headers }
      )

      .then((res) => {
        if (res.data) {
          let order_id = res.data.txnID;
          let order_token = res.data.txnID;

          window.location.assign(res.data.data);

          setTimeout(() => {
            checkgteldeposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: "Failed",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const depositUpiPayGateway = (channel, method, upiMethod, upigateway) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/payupi`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          account_mail_id: account_mail_id,
          payment_gatway: upigateway,
        },
        { headers }
      )

      .then((res) => {
        if (res.data) {
          let order_id = res.data.txnID;
          let order_token = res.data.txnID;

          window.open(res.data.data, "_blank");

          setTimeout(() => {
            checkgteldeposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: "Failed",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
  };
  const depositUpiGGateway = (channel, method, upiMethod, upigateway) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/payupig`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          account_mail_id: account_mail_id,
          payment_gatway: upigateway,
        },
        { headers }
      )

      .then((res) => {
        if (res.data) {
          let order_id = res.data.txnID;
          let order_token = res.data.txnID;
          window.location.assign(res.data.data);
          //window.open(res.data.data, '_blank');

          setTimeout(() => {
            checkgteldeposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: "Failed",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const depositUpiImbGateway = (channel, method, upiMethod, upigateway) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/imbupi`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          account_mail_id: account_mail_id,
          payment_gatway: upigateway,
        },
        { headers }
      )

      .then((res) => {
        if (res.data) {
          let order_id = res.data.txnID;
          let order_token = res.data.txnID;

          window.open(res.data.data, "_blank");

          setTimeout(() => {
            checkgteldeposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: "Failed",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const checkgteldeposit = (order_id, order_token) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `mypaystatus/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        history.push("/landing");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          // localStorage.removeItem('token');
          // localStorage.removeItem('token');
          window.location.reload();
        }
      });
  };

  const checkupideposit = (order_id, order_token) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `mypaystatus/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        history.push("/landing");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          // localStorage.removeItem('token');
          // localStorage.removeItem('token');
          window.location.reload();
        }
      });
  };
  const checkupideposit2 = (order_id, order_token) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `mypaystatus/response2`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        history.push("/landing");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          // localStorage.removeItem('token');
          // localStorage.removeItem('token');
          window.location.reload();
        }
      });
  };

  //use for cashfree gatway
  const deposit = (channel, method, upiMethod) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/deposite`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
        },
        { headers }
      )
      .then((res) => {
        if (res.data.data.payment_method === "app") {
          window.location.href = res.data.data.data.url;
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        } else if (res.data.data.payment_method === "netbanking") {
          window.location.href = res.data.data.data.url;
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        } else if (
          res.data.data.channel === "link" &&
          res.data.data.payment_method === "upi"
        ) {
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
          window.location.href = res.data.data.data.payload[upiMethod];
        } else if (
          res.data.data.channel === "qrcode" &&
          res.data.data.payment_method === "upi"
        ) {
          setQrCode(res.data.data.data.payload.qrcode);
          setProcess(false);
          setNext(3);
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const myPayDepositeUpi = (channel, method, upiMethod, phonepay) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/mypaydepositeapi`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          payment_gatway: phonepay,
        },
        { headers }
      )
      .then((res) => {
        // console.log(res.data.data.qrcodE_STRING);
        // console.log(res.data.mytoken);
        if (res.data.data.status) {
          let mytoken = res.data.mytoken;
          let order_id = res.data.data.clientReferenceId;
          let order_token = res.data.data.paymentReferenceId;
          //setProcess(false);
          window.open(res.data.data.qrcodE_STRING, "_self");
          setTimeout(() => {
            checkmypaydeposit(order_id, order_token, mytoken);
            //history.push('/');
            setProcess(false);
          }, 15000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  };

  const checkmypaydeposit = (order_id, order_token, mytoken) => {
    console.log(order_token);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `mypaystatus/response`,
        { order_id, order_token, mytoken },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction under proccess or failed";

        if (res.data.status == "Pending") {
          setTimeout(() => {
            checkmypaydeposit(order_id, order_token);
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: "OK",
            });
          }, 30000);
        } else {
          history.push("/");
          setTimeout(() => {
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: "OK",
            });
          }, 1000);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const checkout = (paymentID) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `checkout/deposite/txn`,
        { txnID: paymentID },
        { headers }
      )
      .then((res) => {
        // alert(res.data.txnStatus)
        if (res.data.txnStatus === "PAID") {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: res.data.msg,
            icon: "success",
            confirmButtonText: "OK",
          });
          setProcess(false);
          setNext(1);
        } else if (res.data.txnStatus === "FAILED") {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: res.data.msg,
            icon: "error",
            confirmButtonText: "OK",
          });
          setProcess(false);
          setNext(1);
        }
        // else if(res.data.txnStatus!="PENDING")
        // {
        //     clearInterval(checkInterval);
        //     Swal.fire({
        //       title: res.data.msg,
        //       icon: 'info',
        //       confirmButtonText: "OK",
        //   });
        // }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
        setAccount_mail_id(res.data.Email);
        setAccount_name(res.data.holder_name);
        setAccountPhone(res.data.Phone);
        settotalDeposit(res.data.totalDeposit);
        setisverified(res.data.verified);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });

    axios
      .get(baseUrl + `gatewaysettings/data`)
      .then((res) => {
        //console.log(res);
        setCashFreeActive(res.data.isCashFreeActive);
        setRazorPayActive(res.data.isRazorPayActive);
        setDecentroActive(false);
        setPhonePeActive(res.data.PhoneStatus);
        setRazorPayKey(res.data.RazorPayKey);
        setAccountName(res.data.AccountName);
        setManualPaymentActive(res.data.isManualPaymentActive);
        setUpiGatewayActive(res.data.isUpiGatewayActive);
        setUpiGatewayActive2(res.data.isUpiGatewayActive2);
        setIsUpiPayActive(res.data.isUpiPayActive);
        setManualUPIQR(res.data.isManualUPIQR);
        setIsMypayActive(res.data.isMypayActive);
        setGtelGatewayActive(false);
        setIsUpiImbActive(false);
        setIsUpiGActive(res.data.isUpiGActive);
      })
      .catch((e) => {
        setCashFreeActive(false);
        setRazorPayActive(false);
        setDecentroActive(true);
        setPhonePeActive(false);
        setRazorPayKey(false);
        setManualPaymentActive(false);
        setManualUPIQR(false);
        setUpiGatewayActive(false);
        setAccountName("Skill Based Gaming");
      });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setMobile(true);
    }
  }, []);

  const gstRate = 28 / 100; // 28% GST

  // Deposit Amount excluding tax (A)
  const depositAmountExclTax = global / (1 + gstRate);

  // GST amount
  const gstAmount = global - depositAmountExclTax;

  // Cashback Bonus (B) is equal to GST amount
  const cashbackBonus = gstAmount;

  // Wallet Balance (A + B)
  const walletBalance = depositAmountExclTax + cashbackBonus;

  return (
    <>
      <Header user={userAllData} />
      {
        //userAllData && userAllData.verified === 'verified' &&
        <div
          className="leftContainer bg-white"
          style={{ minHeight: "100vh", height: "100%", paddingTop: "60px" }}
        >
          {Boolean(!process) && (
            <div>
              {Boolean(next === 1) && (
                <div className="px-4  py-5">
                  <div className={`${css.games_section}`}>
                    <div className="d-flex position-relative align-items-center">
                      <div className={`${css.games_section_title}`}>
                        Choose amount to add
                      </div>
                    </div>
                  </div>
                  <div className="pb-3">
                    <div className="MultiFormControl_root mt-4 MuiFormControl-fullWidth">
                      <div className="MuiFormControl_root MuiTextField-root">
                        <label
                          className={`${css.MuiFormLabel_root} MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink`}
                          data-shrink="true"
                          style={{ fontSize: "0.8rem", fontWeight: "400" }}
                        >
                          Enter Amount
                        </label>
                        <div className="MuiInputBase-root MuiInput-root MuiInput_underline jss58 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                          <div className="MuiInputAdornment-root MuiInputAdornment-positionStart d-flex w-100">
                            <div className="MuiInputAdornment-root MuiInputAdornment-positionStart d-flex align-items-center">
                              <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary mt-auto">
                                ₹
                              </p>
                            </div>
                            <input
                              className={`w3-input input ${css.collapseCard_title} border-bottom text-dark`}
                              type="tel"
                              id="amountInput"
                              style={{ width: "100%", fontSize: "2em" }}
                              value={`${global}`}
                              onChange={(e) => {
                                e.target.value > 0
                                  ? e.target.value <= 5000
                                    ? setGlobal(parseInt(e.target.value))
                                    : setGlobal(5000)
                                  : e.target.value < 0
                                  ? setGlobal(10)
                                  : setGlobal(0);
                              }}
                            ></input>
                          </div>
                        </div>
                        <p className="MuiFormHelperText-root">
                          Min: 10, Max: 5000
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        borderRadius: "4px",
                        padding: "16px",
                      }}
                    >
                      <div style={{ padding: "0" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "rgb(26, 80, 127)",
                            fontSize: "0.8em",
                            fontWeight: 500,
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Add to Current Balance
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div>
                              ₹{global.toFixed(2)}
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  padding: "0",
                                  marginLeft: "8px",
                                  cursor: "pointer",
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                                tabIndex="0"
                                type="button"
                                aria-expanded={showDetails}
                                aria-label="show more"
                                onClick={handleButtonClick}
                              >
                                <svg
                                  style={{ width: "24px", height: "24px" }}
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                  aria-hidden="true"
                                >
                                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {showDetails && (
                        <div
                          style={{
                            minHeight: "0px",
                            height: "auto",
                            transitionDuration: "263ms",
                          }}
                        >
                          <div>
                            <div>
                              <div style={{ padding: "0" }}>
                                <hr style={{ margin: "0" }} />
                                <div
                                  className="mt-2 row"
                                  style={{
                                    fontSize: "0.7em",
                                    fontWeight: "400",
                                  }}
                                >
                                  <div className="col-8">
                                    Deposit Amount (excl. Govt. Tax)
                                    <span
                                      className="ml-2 px-2"
                                      style={{
                                        color: "rgb(26, 80, 127)",
                                        backgroundColor: "rgb(216, 233, 249)",
                                      }}
                                    >
                                      A
                                    </span>
                                  </div>
                                  <div className="col-4 d-flex justify-content-end text-success">
                                    ₹{depositAmountExclTax.toFixed(2)}
                                  </div>
                                </div>
                                <div
                                  className="my-2 row"
                                  style={{
                                    fontSize: "0.7em",
                                    fontWeight: "400",
                                  }}
                                >
                                  <div className="col-8">
                                    Govt. Tax. (28% GST)
                                  </div>
                                  <div className="col-4 d-flex justify-content-end">
                                    ₹{gstAmount.toFixed(2)}
                                  </div>
                                </div>
                                <hr style={{ margin: "0" }} />
                                <div
                                  className="mt-2 row"
                                  style={{
                                    fontSize: "0.7em",
                                    fontWeight: "400",
                                  }}
                                >
                                  <div className="col-8">Total</div>
                                  <div className="col-4 d-flex justify-content-end">
                                    ₹{global.toFixed(2)}
                                  </div>
                                </div>
                                <div
                                  className="my-2 row"
                                  style={{
                                    fontSize: "0.7em",
                                    fontWeight: "400",
                                  }}
                                >
                                  <div className="col-8">
                                    😍 Deposit Bonus
                                    <span
                                      className="ml-2 px-2"
                                      style={{
                                        color: "rgb(26, 80, 127)",
                                        backgroundColor: "rgb(216, 233, 249)",
                                      }}
                                    >
                                      B
                                    </span>
                                  </div>
                                  <div className="col-4 d-flex justify-content-end text-success">
                                    ₹{cashbackBonus.toFixed(2)}
                                  </div>
                                </div>
                                <hr style={{ margin: "0" }} />
                                <div
                                  className="my-2 row"
                                  style={{
                                    fontSize: "0.7em",
                                    fontWeight: "500",
                                  }}
                                >
                                  <div className="col-8">
                                    Add to Current Balance
                                    <span
                                      className="ml-2 mr-2 px-2"
                                      style={{
                                        color: "rgb(26, 80, 127)",
                                        backgroundColor: "rgb(216, 233, 249)",
                                      }}
                                    >
                                      A
                                    </span>
                                    +
                                    <span
                                      className="ml-2 px-2"
                                      style={{
                                        color: "rgb(26, 80, 127)",
                                        backgroundColor: "rgb(216, 233, 249)",
                                      }}
                                    >
                                      B
                                    </span>
                                  </div>
                                  <div className="col-4 d-flex justify-content-end text-success">
                                    ₹{walletBalance.toFixed(2)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className={`${css.games_window}`}></div>
                  </div>
                  <div className={`${css.refer_footer}`}>
                    <div className="d-grid gap-2 col-12 mx-auto">
                      <button
                        type="button"
                        className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`}
                        onClick={() => {
                          if (
                            totalDeposit + global > 500 &&
                            isverified == "unverified"
                          ) {
                            alert("complete for kyc");
                            window.location.href = "/kyc2";
                            return;
                          }
                          global >= 10
                            ? setNext(2)
                            : alert("Minimum deposit is 10");
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {Boolean(next === 2) && (
                <div className="px-4 py-3">
                  <div className="pb-3">
                    <div className={`${css.games_section}`}>
                      <div className="d-flex position-relative align-items-center justify-content-between">
                        <div
                          className={`${css.games_section_title}`}
                          style={{ fontSize: "1.1em" }}
                        >
                          Amount to be added ₹<span>{global}</span>
                        </div>
                        <button
                          type="button"
                          onClick={() => setNext(1)}
                          className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      background: "rgb(241, 241, 241)",
                      width: "100%",
                      height: "10px",
                      position: "absolute",
                      left: "0",
                      right: "0",
                    }}
                  ></div>
                  <div className="d-flex flex-column mt-4">
                    <div className="games-section-title">Pay Through UPI</div>
                    {/* Boolean(isUpiGatewayActive) &&
              <div>
              <label htmlFor="username " className="mr-5">
                  <i className="far fa-bank mr-2"></i>Account holder name
                </label>
                <div className="col-12 mb-3 p-0">
                  <input
                    type="text"
                    className="form-control"
                    id="account_name"
                    placeholder="Enter Account Name"
                    name="acname"
                    value={account_name}
                    onChange={(e) => setAccount_name(e.target.value)}
                    required
                  />
                </div>

                <label htmlFor="username " className="mr-5">
                  <i className="far fa-bank mr-2"></i>Enter Your Mail ID
                </label>
                <div className="col-12 mb-3 p-0">
                  <input
                    type="text"
                    className="form-control"
                    id="account_mail_id"
                    placeholder="Enter Your Mail ID"
                    name="mailid"
                    value={account_mail_id}
                    onChange={(e) => setAccount_mail_id(e.target.value)}
                    required
                  />
                </div>
                  </div> */}
                    {Boolean(isMypayActive) && (
                      <div
                        onClick={() => {
                          method.current = "upipay";
                          myPayDepositeUpi("link", "upi", "upipay", "mypayapi");
                        }}
                        className="add-fund-box mt-3"
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              height: "60px",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <img
                              width="40px"
                              src="UPI.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Add Wallet</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}

                    {Boolean(isUpiGatewayActive) && (
                      <>
                        <div
                          onClick={() => {
                            method.current = "upipay";
                            depositUpiGateway(
                              "link",
                              "upi",
                              "upipay",
                              "upigateway"
                            );
                          }}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: "#fafafa",
                              border: "1px solid #e0e0e0",
                              borderRadius: "7px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{
                                height: "60px",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                width="40px"
                                src="https://cdn.iconscout.com/icon/free/png-128/free-paytm-logo-icon-download-in-svg-png-gif-file-formats--online-payment-brand-logos-pack-icons-226448.png?f=webp"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="jss30">
                                <strong>Paynow</strong>
                              </div>
                              <div className="jss31"></div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {Boolean(isUpiGatewayActive2) && (
                      <>
                        <div
                          onClick={() => {
                            method.current = "upipay";
                            depositUpiGateway2(
                              "link",
                              "upi",
                              "upipay",
                              "upigateway"
                            );
                          }}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: "#fafafa",
                              border: "1px solid #e0e0e0",
                              borderRadius: "7px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{
                                height: "60px",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                width="40px"
                                src="UPI.png"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="jss30">
                                <strong>Pay now UPI</strong>
                              </div>
                              <div className="jss31"></div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {Boolean(isGtelGatewayActive) && (
                      <div
                        onClick={() => {
                          method.current = "upipay";
                          depositGtelGateway("link", "upi", "upipay", "upipay");
                        }}
                        className="add-fund-box mt-3"
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              height: "60px",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <img
                              width="40px"
                              src="https://w7.pngwing.com/pngs/345/591/png-transparent-phonepe-hd-logo.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Paynow</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(isUpiPayActive) && (
                      <div
                        onClick={() => {
                          method.current = "upipay";
                          depositUpiPayGateway(
                            "link",
                            "upi",
                            "upipay",
                            "upipay"
                          );
                        }}
                        className="add-fund-box mt-3"
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              height: "60px",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <img
                              width="40px"
                              src="https://w7.pngwing.com/pngs/345/591/png-transparent-phonepe-hd-logo.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Paynow</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}

                    {Boolean(isUpiGActive) && (
                      <div
                        onClick={() => {
                          method.current = "upipay";
                          depositUpiGGateway("link", "upi", "upipay", "upipay");
                        }}
                        className="add-fund-box mt-3"
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              height: "60px",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <img
                              width="40px"
                              src="https://w7.pngwing.com/pngs/345/591/png-transparent-phonepe-hd-logo.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Paynow</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(isUpiImbActive) && (
                      <div
                        onClick={() => {
                          method.current = "upipay";
                          depositUpiImbGateway(
                            "link",
                            "upi",
                            "upipay",
                            "upipay"
                          );
                        }}
                        className="add-fund-box mt-3"
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              height: "60px",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <img
                              width="40px"
                              src="https://cdn.iconscout.com/icon/free/png-256/free-upi-logo-icon-download-in-svg-png-gif-file-formats--unified-payments-interface-payment-money-transfer-logos-icons-1747946.png?f=webp"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Paynow</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(isRazorPayActive) && (
                      <div
                        onClick={() => {
                          method.current = "upipay";
                          displayRazorpayPaymentSdk(
                            "link",
                            "upi",
                            "upipay",
                            "razorpay"
                          );
                        }}
                        className="add-fund-box mt-3"
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              height: "60px",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <img
                              width="40px"
                              src="UPI.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Deposit Here</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                    <br />

                    {Boolean(isPhonePeActive) && (
                      <div
                        onClick={() => {
                          method.current = "upipay";
                          phonePeDepositeUpi(
                            "link",
                            "upi",
                            "upipay",
                            "Phonepe"
                          );
                        }}
                        className="add-fund-box mt-3"
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              height: "60px",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <img
                              width="40px"
                              src="UPI.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Deposit PhonePe</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(isDecentroActive) && (
                      <div
                        onClick={() => {
                          method.current = "upipay";
                          decentroDepositeUpi(
                            "link",
                            "upi",
                            "upipay",
                            "decentropay"
                          );
                        }}
                        className="add-fund-box mt-3"
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              height: "60px",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <img
                              width="40px"
                              src="UPI.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Desposit Now</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(isManualPaymentActive) && (
                      <div className="m-2">
                        <img
                          src={baseUrl + isManualUPIQR}
                          style={{ width: "100%" }}
                          alt="img"
                        />
                      </div>
                    )}
                    {Boolean(isManualPaymentActive) && (
                      <form
                        onSubmit={ManualPayment}
                        method="POST"
                        encType="multipart/form-data"
                      >
                        <label htmlFor="username " className="mr-5">
                          <i className="far fa-bank mr-2"></i>Enter UTR Number
                        </label>
                        <div className="col-12 mb-3 p-0">
                          <input
                            type="text"
                            className="form-control"
                            id="referenceId"
                            placeholder="Enter UTR Number"
                            name="referenceId"
                            value={upiUtrNum}
                            onChange={(e) => setupiUtrNum(e.target.value)}
                            required
                          />
                        </div>

                        <div
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: "#fafafa",
                              border: "1px solid #e0e0e0",
                              borderRadius: "7px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{ textAlign: "center" }}
                            >
                              <img
                                width="40px"
                                src="UPI.png"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="jss30">
                                <strong>Upload Screenshot</strong>
                              </div>
                              <div className="jss31">
                                <input
                                  type="file"
                                  name="Screenshot"
                                  onChange={handleChange}
                                  accept="image/*"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className=" m-2 text-center">
                          <button type="submit" className="btn btn-success">
                            Submit
                          </button>
                        </div>

                        <div style={{ width: "100%", height: "400px" }}>
                          <img src={scrnshot1} style={{ width: "100%" }} />
                        </div>
                      </form>
                    )}
                    {Boolean(isMobile) && Boolean(isCashFreeActive) && (
                      <div>
                        <div
                          onClick={() => {
                            method.current = "gpay";
                            deposit("link", "upi", "gpay");
                          }}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: "#fafafa",
                              border: "1px solid #e0e0e0",
                              borderRadius: "7px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{
                                height: "60px",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                width="40px"
                                src="gpay-logo.png"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="jss30">
                                <strong>G-Pay</strong>
                              </div>
                              <div className="jss31"></div>
                            </div>
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            method.current = "phonepe";
                            deposit("link", "upi", "phonepe");
                          }}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: "#fafafa",
                              border: "1px solid #e0e0e0",
                              borderRadius: "7px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{
                                height: "60px",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                width="40px"
                                src="/phonepe-logo.png"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="jss30">
                                <strong>PhonePe</strong>
                              </div>
                              <div className="jss31"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            method.current = "paytm";
                            deposit("link", "upi", "paytm");
                          }}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              backgroundColor: "#fafafa",
                              border: "1px solid #e0e0e0",
                              borderRadius: "7px",
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{
                                height: "60px",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                width="40px"
                                src="/paytm-logo.png"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="jss30">
                                <strong>Paytm UPI</strong>
                              </div>
                              <div className="jss31"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(!isMobile) && Boolean(isCashFreeActive) && (
                      <div
                        className="add-fund-box mt-3"
                        onClick={() => {
                          deposit("qrcode", "upi");
                        }}
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{ height: "60px" }}
                          >
                            <img
                              width="45px"
                              src="/qr-scan.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingLeft: "3px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Scan QR Code</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(isCashFreeActive) && (
                      <div className="games-section-title mt-3">
                        Other Options
                      </div>
                    )}
                    {Boolean(isCashFreeActive) && (
                      <div
                        className="add-fund-box mt-3"
                        onClick={() => {
                          setNext(4);
                        }}
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{ height: "60px" }}
                          >
                            <img
                              width="45px"
                              src="all-wallets.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Other Wallets</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {Boolean(isCashFreeActive) && (
                      <div
                        className="add-fund-box mt-3"
                        onClick={() => {
                          setNext(5);
                        }}
                        style={{ paddingTop: "0px", height: "60px" }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#fafafa",
                            border: "1px solid #e0e0e0",
                            borderRadius: "7px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{ height: "60px" }}
                          >
                            <img
                              width="45px"
                              src="/bank1.png"
                              alt=""
                              style={{
                                marginLeft: "7px",
                                paddingBottom: "10px",
                                paddingLeft: "3px",
                                paddingTop: "5px",
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center flex-column ml-4">
                            <div className="jss30">
                              <strong>Net Banking</strong>
                            </div>
                            <div className="jss31"></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {Boolean(next === 3) && (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <img src={qrCode} alt="img" />
            </div>
          )}
          {Boolean(next === 4) && (
            <div className="px-4 py-3">
              <div className="pb-3">
                <div className={`${css.games_section}`}>
                  <div className="d-flex position-relative align-items-center justify-content-between">
                    <div
                      className={`${css.games_section_title}`}
                      style={{ fontSize: "1.1em" }}
                    >
                      Amount to be added ₹<span>{global}</span>
                    </div>
                    <button
                      type="button"
                      onClick={() => setNext(1)}
                      className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: "rgb(241, 241, 241)",
                  width: "100%",
                  height: "10px",
                  position: "absolute",
                  left: "0",
                  right: "0",
                }}
              ></div>
              <div className="d-flex flex-column mt-4">
                <div className="games-section-title">Pay Through</div>
                <div
                  className="add-fund-box mt-3"
                  style={{ paddingTop: "0px", height: "60px" }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "60px" }}
                    >
                      <img
                        width="45px"
                        src="all-wallets.png"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingLeft: "3px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>Other Wallets</strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                    <div className="d-flex justify-content-center ml-auto mr-3">
                      <button
                        type="button"
                        onClick={() => setNext(2)}
                        className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <select
                className="form-control mt-4"
                style={{
                  border: "1px solid rgb(224, 224, 224)",
                  outline: "none",
                  borderRadius: "5px",
                  background: "rgb(250, 250, 250)",
                  height: "3rem",
                }}
                onChange={(e) => {
                  setWalletOption(e.target.value);
                }}
              >
                <option value="airtel">Airtel Money</option>
                <option value="freecharge">Freecharge</option>
                <option value="mobikwik">Mobikwik</option>
                <option value="ola">Ola Money</option>
                <option value="jio">Reliance Jio Money</option>
              </select>
              <div className={`${css.refer_footer}`}>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <button
                    type="button"
                    className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`}
                    onClick={() => {
                      deposit("link", "app");
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
          {Boolean(next === 5) && (
            <div className="px-4 py-3">
              <div className="pb-3">
                <div className={`${css.games_section}`}>
                  <div className="d-flex position-relative align-items-center justify-content-between">
                    <div
                      className={`${css.games_section_title}`}
                      style={{ fontSize: "1.1em" }}
                    >
                      Amount to be added ₹<span>{global}</span>
                    </div>
                    <button
                      type="button"
                      onClick={() => setNext(1)}
                      className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: "rgb(241, 241, 241)",
                  width: "100%",
                  height: "10px",
                  position: "absolute",
                  left: "0",
                  right: "0",
                }}
              ></div>
              <div className="d-flex flex-column mt-4">
                <div className="games-section-title">Pay Through</div>
                <div
                  className="add-fund-box mt-3"
                  style={{ paddingTop: "0px", height: "60px" }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #e0e0e0",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "60px" }}
                    >
                      <img
                        width="45px"
                        src="/bank1.png"
                        alt=""
                        style={{
                          marginLeft: "7px",
                          paddingLeft: "3px",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss30">
                        <strong>Net Banking</strong>
                      </div>
                      <div className="jss31"></div>
                    </div>
                    <div className="d-flex justify-content-center ml-auto mr-3">
                      <button
                        type="button"
                        onClick={() => setNext(2)}
                        className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <select
                className="form-control mt-4"
                style={{
                  border: "1px solid rgb(224, 224, 224)",
                  outline: "none",
                  borderRadius: "5px",
                  background: "rgb(250, 250, 250)",
                  height: "3rem",
                }}
                onChange={(e) => {
                  setBankCode(e.target.value);
                }}
              >
                <option value="3102">Jana Small Finance Bank</option>
              </select>
              <div className={`${css.refer_footer}`}>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <button
                    type="button"
                    className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`}
                    onClick={() => {
                      deposit("link", "netbanking");
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}
          {Boolean(process) && (
            <div className="loaderReturn" style={{ zIndex: "99" }}>
              <img
                src={
                  "https://cdn.pixabay.com/animation/2023/10/08/03/19/03-19-26-213_512.gif"
                }
                style={{ width: "100%" }}
                alt="img"
              />
            </div>
          )}
        </div>
      }
      {/* <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>

        <div className="container px-3 mt-5 py-5" style={{ height: "10px" }}>
          <div className="row">

            <div className="col mx-auto">
              <div className="card text-center mt-3">

                {userAllData && userAllData.verified === 'unverified' && <div style={{ height: "100px" }}>
                  <Link to='/Kyc2'>

                    <picture className="ml-3">
                      <img src="/images/alert.svg" alt="" width="32px" className="mt-4" />
                    </picture>
                    <div className="ml-1 mt-2 mytext text-muted ">
                      Complete KYC to Deposit Amount
                    </div>
                  </Link>
                </div>}
                {userAllData && userAllData.verified === 'pending' && <div style={{ height: "100px" }}>
                  <picture className="ml-3">
                    <img src="/images/alert.svg" alt="" width="32px" className="mt-4" />
                  </picture>
                  <div className="ml-1 mt-2 mytext text-muted ">
                    Please wait your kyc under process
                  </div>
                </div>}

              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default Addcase;
